<template>
    <div class="page">
        <div class="page-header pd">
            <div>
                <sc-button icon="plus" @click="$router.push(`new`)" v-if="$user.isAtLeast(1)">Add {{
                        resource.name
                    }}
                </sc-button>
            </div>

            <div class="filters">
                <nr-radio class="radio" :value.sync="filters" @update:value="search(query)" :multi="true" :options="[
                  {value:'active', label:'Online'},
                  {value:'overdue', label:'Offline < 24 hours'},
                  {value:'offline', label:'Offline'},
            ]">

                </nr-radio>
                <sc-search @searched="search"></sc-search>
            </div>
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-last_online="{row}">

                <sc-table-cell :table="table" field="last_online" :padded="false">
                    <div class="status">
                        <div class="status-inner status-positive" v-if="playerHealthy(row)">
                            <div class="icon">
                                <awesome-icon icon="check"/>
                            </div>
                            <div class="status-name">
                                {{ row.attributes.last_reported | toDateHuman }}
                            </div>
                        </div>
                        <div v-else class="status-inner status-negative">
                            <div class="icon">
                                <awesome-icon icon="ban"/>
                            </div>
                            <div class="status-name">
                                <span v-if="row.attributes.last_reported">
                                     {{ row.attributes.last_reported | toDateHuman }}
                                </span>
                                <span v-else>
                                    Never
                                </span>
                            </div>
                        </div>
                    </div>
                    <template #sub>{{ row.attributes.last_reported | toDatetime }}</template>
                </sc-table-cell>

            </template>

            <template #column-channel="{row}">
                <sc-table-cell :table="table" field="channel" :padded="false" :clickable="false">
                    <div class="channel-select" @click="setChannelSelect(row)" v-if="$user.isAtLeast(2)">
                        <div class="channel-name" v-if="row.channel">
                            {{ row.channel.data.attributes.name }}
                        </div>
                        <div class="channel-icon">
                            <awesome-icon icon="chevron-down"></awesome-icon>
                        </div>
                    </div>
                    <div v-else>
                        {{ row.channel.data.attributes.name }}
                    </div>
                </sc-table-cell>
            </template>


            <template #column-plan="{row}">
                <sc-table-cell :table="table" field="plan" :padded="false" :clickable="false">
                    <sc-table-button @click="plan(row)" icon="sync">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-details="{row}">
                <sc-table-cell :table="table" field="details" :padded="false" :clickable="false">
                    <sc-table-button @click="details = row" icon="eye">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>

        </sc-table>

        <player-details v-if="details" @close="details = null" :player="details"/>

        <sc-modal title="Channel change" v-if="channelSelect" @close="stopChannelSelect">
            Select a new channel for player "{{ channelSelect.attributes.name }}"
            <select v-model="channel" v-if="channels.length > 0" style="margin:1.5em 0 0;">
                <option v-for="chn in channels" :value="chn.id">
                    {{ chn.attributes.name }}
                </option>
            </select>
            <div v-else class="loading">Loading channels...</div>
            <div class="btns">
                <sc-button @click="applyChannelSelect">Confirm</sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
import PlayerDetails from "@/views/app/players/components/player-details";
import moment from 'moment-timezone';

export default {
  name: "all",
  components: {PlayerDetails, ScSearch},

  data() {
    return {
      table: null,
      query: null,
      details: null,
      filters: ['active', 'overdue', 'offline'],
      channels: [],
      channelSelect: null,
      channel: null
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      sortBy: 'name',
      columns: {
        id: {
          width: 80,
          sort: false,
        },
        name: {
          target: 'attributes.name',
          sort: false,
          fill: true,
          width: 250,
        },
        asset_id: {
          name: 'Asset ID',
          target: 'attributes.asset_id',
          width: 110,
          sort: false,
        },
        last_online: {
          target: 'attributes.last_reported',
          type: 'date',
          sort: false,
          width: 180,
        },
        channel: {
          target: 'channel.data.attributes.name',
          width: 250,
          sort: false,
          fill: true,
        },
        plan: {width: 70, sort: false, guard: !this.$user.isAtLeast(2)},
        edit: {width: 70, sort: false, guard: !this.$user.isAtLeast(1)},
        details: {width: 70, sort: false,},
        delete: {width: 80, sort: false, guard: !this.$user.isAtLeast(1)}
      },
      hover: false,
    });
    this.table.fetchArguments = () => {
      let args = {
        query: this.query,
        status: {
          active: this.filters.includes('active'),
          overdue: this.filters.includes('overdue'),
          offline: this.filters.includes('offline'),
        }
      }
      return `&filters=${JSON.stringify(args)}`;
    }
    this.table.fetch();

    this.fetchChannels();
  },

  methods: {

    fetchChannels() {
      this.$talker.api('channels?amount=10000&relations=exclude')
        .then(res => {
          this.channels = res.data.data
        })
    },

    setChannelSelect(row) {
      this.channelSelect = row;
      if (row.channel) {
        this.channel = row.channel.data.id;
      }
    },

    stopChannelSelect() {
      this.channelSelect = null;
      this.channel = null
    },

    applyChannelSelect() {
      let payload = {
        channel_id: this.channel,
      }
      this.$talker.api.put(`players/${this.channelSelect.id}/channel`, payload)
        .then(res => {
          this.$notify.success('Channel updated')
          this.table.fetch();
          this.stopChannelSelect();
        })
    },

    playerHealthy(row) {
      if (!row.attributes.last_reported) {
        return false;
      }
      if (row.attributes.active !== 'ACTIVE') {
        return false;
      }
      if (moment().diff(row.attributes.last_reported, 'hours') > 24) {
        return false
      }
      return true;
    },

    search(search) {
      this.query = search
      this.table.fetchArguments = () => {
        let args = {
          query: search,
          status: {
            active: this.filters.includes('active'),
            overdue: this.filters.includes('overdue'),
            offline: this.filters.includes('offline'),
          }
        }
        return `&filters=${JSON.stringify(args)}`;
      }
      this.table.page = 1;
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
    },

    plan(row) {
      this.$talker.api.put(`${this.resource.api}/${row.id}/plan`)
        .then(res => {
          this.$notify.success(`Plan for "${row.attributes.name}" has been generated`)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.status {
    display: inline-block;

    .status-inner {
        display: flex;
        color: #fff;
        font-weight: 700;
        font-size: 0.8em;
        padding: 0 0.5em;
        border-radius: 0.4em;
    }

    .icon {
        margin-right: 0.3em;
        width: 1.3em;
    }

    .status-positive {
        background-color: green;
    }

    .status-negative {
        background-color: $color-secondary;
    }
}

.filters {
    display: flex;
    align-items: center;
}

.radio.radio-buttons {
    display: flex;
    align-items: center;
    margin-right: 3em;
}

.channel-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    padding: 0.5em 0.8em;
    margin: 0.2em 0;
    border-radius: 0.3em;
    cursor: pointer;

    &:hover {
        border: 1px solid #aaa;
    }

    .channel-name {
        margin-right: 0.5em;
        line-height: 1.2em;
    }

    .channel-icon {
        color: #777;
    }
}

.loading {
    padding: 1em;
    color: #333;
}
</style>