var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header pd"},[_c('div',[(_vm.$user.isAtLeast(1))?_c('sc-button',{attrs:{"icon":"plus"},on:{"click":function($event){return _vm.$router.push("new")}}},[_vm._v("Add "+_vm._s(_vm.resource.name)+" ")]):_vm._e()],1),_c('div',{staticClass:"filters"},[_c('nr-radio',{staticClass:"radio",attrs:{"value":_vm.filters,"multi":true,"options":[
              {value:'active', label:'Online'},
              {value:'overdue', label:'Offline < 24 hours'},
              {value:'offline', label:'Offline'} ]},on:{"update:value":[function($event){_vm.filters=$event},function($event){return _vm.search(_vm.query)}]}}),_c('sc-search',{on:{"searched":_vm.search}})],1)]),(_vm.table)?_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-last_online",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"last_online","padded":false},scopedSlots:_vm._u([{key:"sub",fn:function(){return [_vm._v(_vm._s(_vm._f("toDatetime")(row.attributes.last_reported)))]},proxy:true}],null,true)},[_c('div',{staticClass:"status"},[(_vm.playerHealthy(row))?_c('div',{staticClass:"status-inner status-positive"},[_c('div',{staticClass:"icon"},[_c('awesome-icon',{attrs:{"icon":"check"}})],1),_c('div',{staticClass:"status-name"},[_vm._v(" "+_vm._s(_vm._f("toDateHuman")(row.attributes.last_reported))+" ")])]):_c('div',{staticClass:"status-inner status-negative"},[_c('div',{staticClass:"icon"},[_c('awesome-icon',{attrs:{"icon":"ban"}})],1),_c('div',{staticClass:"status-name"},[(row.attributes.last_reported)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toDateHuman")(row.attributes.last_reported))+" ")]):_c('span',[_vm._v(" Never ")])])])])])]}},{key:"column-channel",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"channel","padded":false,"clickable":false}},[(_vm.$user.isAtLeast(2))?_c('div',{staticClass:"channel-select",on:{"click":function($event){return _vm.setChannelSelect(row)}}},[(row.channel)?_c('div',{staticClass:"channel-name"},[_vm._v(" "+_vm._s(row.channel.data.attributes.name)+" ")]):_vm._e(),_c('div',{staticClass:"channel-icon"},[_c('awesome-icon',{attrs:{"icon":"chevron-down"}})],1)]):_c('div',[_vm._v(" "+_vm._s(row.channel.data.attributes.name)+" ")])])]}},{key:"column-plan",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"plan","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"sync"},on:{"click":function($event){return _vm.plan(row)}}})],1)]}},{key:"column-edit",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"edit","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.edit(row)}}})],1)]}},{key:"column-details",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"eye"},on:{"click":function($event){_vm.details = row}}})],1)]}},{key:"column-delete",fn:function(ref){
        var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete the "+_vm._s(_vm.resource.name)+" \""+_vm._s(row.attributes.name)+"\"? ")])],1)]}}],null,false,3263775863)}):_vm._e(),(_vm.details)?_c('player-details',{attrs:{"player":_vm.details},on:{"close":function($event){_vm.details = null}}}):_vm._e(),(_vm.channelSelect)?_c('sc-modal',{attrs:{"title":"Channel change"},on:{"close":_vm.stopChannelSelect}},[_vm._v(" Select a new channel for player \""+_vm._s(_vm.channelSelect.attributes.name)+"\" "),(_vm.channels.length > 0)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.channel),expression:"channel"}],staticStyle:{"margin":"1.5em 0 0"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.channel=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.channels),function(chn){return _c('option',{domProps:{"value":chn.id}},[_vm._v(" "+_vm._s(chn.attributes.name)+" ")])}),0):_c('div',{staticClass:"loading"},[_vm._v("Loading channels...")]),_c('div',{staticClass:"btns"},[_c('sc-button',{on:{"click":_vm.applyChannelSelect}},[_vm._v("Confirm")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }