<template>
    <div>
        <sc-modal @close="$emit('close')" title="Player summary">

            <div class="cols" v-if="item">

                <div class="col">
                    <div class="header">
                        Playlists
                    </div>
                    <div class="playlists">
                        <div class="playlist" v-for="playlist in item.playlists">
                            <div class="playlist-row">
                                <div class="playlist-id">
                                    {{ playlist.id }}
                                </div>
                                <div class="playlist-name">
                                    {{ playlist.attributes.name }}
                                </div>
                                <div class="playlist-link">
                                    <sc-button @click="$router.push(`/app/playlists/${playlist.id}/edit`)"
                                               v-if="$user.isAtLeast(3)">Open
                                        playlist
                                    </sc-button>
                                </div>

                            </div>
                            <div class="sub-sections" v-if="playlist.playlist_playlistItems">
                                <div class="sub-section">
                                    <div class="label">Playlists</div>
                                    <table class="sub-items">
                                        <tr class="headers">
                                            <th class="item-header id">ID</th>
                                            <th class="item-header">Name</th>
                                            <th class="item-header">Image</th>
                                        </tr>
                                        <tr class="item" v-for="itempje in playlist.playlist_playlistItems.data"
                                            v-if="itempje.attributes.playlist_item_type === 'playlist'">
                                            <td class="item-value id">{{ itempje.id }}</td>
                                            <td class="item-value">
                                                <div class="item-name">{{ itempje.attributes.name }}</div>
                                                <div class="item-count" style="margin-top:0.5em;margin-left:0.8em"
                                                     v-if="itempje.count">
                                                    <div>Contains:</div>
                                                    <div v-if="itempje.count.media > 0">- media item:
                                                        {{ itempje.count.media }}
                                                    </div>
                                                    <div v-if="itempje.count.playlists > 0">- playlists:
                                                        {{ itempje.count.playlists }}
                                                    </div>
                                                </div>
                                                <div class="item-times" v-if="itempje.attributes.schedule">
                                                    <div class="time" v-for="time in itempje.attributes.schedule.times">
                                                        <span>{{ time.start_time }}</span> -
                                                        <span>{{ time.end_time }}</span>
                                                        <div class="days">
                                                            <span class="day" v-for="day in time.days"
                                                                  v-if="day !== false">
                                                                {{ day.substring(0, 3) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="item-value"><img :src="imageURL(itempje.attributes.thumb)"/></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="sub-section">
                                    <div class="label">Media items</div>
                                    <table class="sub-items">
                                        <tr class="headers">
                                            <th class="item-header id">ID</th>
                                            <th class="item-header">Name</th>
                                            <th class="item-header">Image</th>
                                        </tr>
                                        <tr class="item" v-for="itempje in playlist.playlist_playlistItems.data"
                                            v-if="itempje.attributes.playlist_item_type === 'media'">
                                            <td class="item-value id">{{ itempje.id }}</td>
                                            <td class="item-value">
                                                <div class="item-name">{{ itempje.attributes.name }}</div>
                                                <div class="item-times" v-if="itempje.attributes.schedule">
                                                    <div class="time" v-for="time in itempje.attributes.schedule.times">
                                                        <span>{{ time.start_time }}</span> -
                                                        <span>{{ time.end_time }}</span>
                                                        <div class="days">
                                                            <span class="day" v-for="day in time.days" v-if="day !== false">
                                                                {{ day.substring(0, 3) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="item-value"><img :src="imageURL(itempje.attributes.thumb)"/></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="header">
                        Live Operational Messages
                    </div>
                    <div class="sub-section">
                        <table class="sub-items">
                            <tr class="headers">
                                <th class="item-header id">ID</th>
                                <th class="item-header">Priority</th>
                                <th class="item-header">Name</th>
                                <th class="item-header">Image</th>
                            </tr>
                            <tr class="item" v-for="itempje in item.active_templates.data">
                                <td class="item-value id">{{ itempje.id }}</td>
                                <td class="item-value">{{ getPriorityName(itempje.attributes.priority_id) }}</td>
                                <td class="item-value">
                                    <template v-if="itempje.attributes.type_id !== 4">
                                        <div>{{ itempje.attributes.title }}</div>
                                    </template>
                                    <template v-else>
                                        <div>Image only</div>
                                    </template>
                                    <div class="expire" v-if="itempje.attributes.end_date">Expires in
                                        {{ itempje.attributes.end_date | toDateHuman }}
                                    </div>
                                    <div class="start-date">{{ itempje.attributes.start_date | toDatetime }}</div>
                                    <div class="start-date" v-if="itempje.attributes.end_date">to
                                        {{ itempje.attributes.end_date | toDatetime }}
                                    </div>
                                </td>
                                <td class="item-value">
                                    <img v-if="itempje.attributes.bg_image" :src="imageURL(itempje.attributes.bg_image)"/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>

        </sc-modal>
    </div>
</template>

<script>
export default {
  name: "player-details",

  props: {
    player: {}
  },

  data() {
    return {
      item: null,
      priorities: [],
      playlistCounts: []
    }
  },

  computed: {},

  mounted() {
    this.$talker.api(`content-player/${this.player.id}`)
      .then(res => {
        this.item = res.data.data

        for (let i = 0; i < this.item.playlists.length; i++) {

          if (!this.item.playlists[i].playlist_playlistItems) continue;
          for (let j = 0; j < this.item.playlists[i].playlist_playlistItems.data.length; j++) {
            let playlist = this.item.playlists[i].playlist_playlistItems.data[j]
            if (playlist.attributes.playlist_item_type !== 'playlist') continue;
            this.getPlaylistCount(playlist)

          }

        }
      })

    this.$talker.api('templates/priorities')
      .then(res => {
        this.priorities = res.data.data;
      })
  },

  methods: {

    getPlaylistCount(playlist) {
      let id = playlist.attributes.playlist_item_id
      this.$talker.api(`playlists/${id}`)
        .then(res => {
          if (res.data.data.playlist_playlistItems) {
            let counts = {
              media: 0,
              playlists: 0,
            }
            res.data.data.playlist_playlistItems.data.map(x => {
              if (x.attributes.playlist_item_type === 'media') {
                counts.media++
              }
              if (x.attributes.playlist_item_type === 'playlists') {
                counts.playlists++
              }
            })
            this.$set(playlist, 'count', counts)
          }
        })
    },

    imageURL(file) {
      return process.env.VUE_APP_AWS_URL + file;
    },

    getPriorityName(id) {
      let prio = this.priorities.find(x => x.id === id);
      if (prio) {
        return prio.attributes.title
      } else {
        return id
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cols {
    display: flex;

    .col {
        width: 50%;

        &:first-child {
            margin-right: 2em;
        }

        &:last-child {
            margin-left: 2em;
        }
    }

    .header {
        font-weight: 600;
        margin-bottom: 1em;
        font-size: 1.2em;
    }

    .playlist-row {
        display: flex;

        .playlist-id {
            min-width: 3em;
        }

        .playlist-name {
            flex: 1;
        }
    }

    img {
        max-width: 100%;
        max-height: 6em;
    }

    .sub-section {
        min-width: 400px;
        font-size: 0.8em;
        line-height: 1.2em;

        .label {
            margin-top: 1em;
            margin-bottom: 0.3em;
        }

        table {
            border-collapse: collapse;
            width: 100%;

            tr {
                vertical-align: center;

                th {
                    padding: 0.3em;
                    border: 1px solid #ddd;
                }

                td {
                    border: 1px solid #ddd;
                    padding: 0.3em;
                    vertical-align: middle;

                    &.id {
                        width: 4em;
                    }
                }
            }

        }
    }
}


.item-times {
    margin-top: 0.8em;

    .time {
        .days {
            padding: 0.2em 0 0.5em;

            .day {
                background: $color-primary;
                color: #fff;
                margin-right: 0.2em;
                margin-bottom: 0.2em;
                text-transform: lowercase;
                font-weight: 600;
                font-size: 0.8em;
                text-align: center;
                padding: 0.3em 0.5em;
                min-width: 2em;
                border-radius: 100px;
                display: inline-block;
            }
        }
    }
}


</style>